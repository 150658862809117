import * as React from "react";
import { Maintenance as BaseMaintenance } from "@nju/feature-portal-technical";
import { useSellerLoginState } from "@nju/data-access-sales-seller";
import { IScope } from "@nju/scope";

interface IProps {
  scope: IScope;
}

export function Maintenance({
  children,
  ...props
}: React.PropsWithChildren<IProps>) {
  const { isLoggedIn } = useSellerLoginState();

  return (
    <BaseMaintenance {...props} isSellerLoggedIn={isLoggedIn}>
      {children}
    </BaseMaintenance>
  );
}
