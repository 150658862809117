import { NextSeo } from "next-seo";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import { maybeRefreshTokens } from "@nju/data-access-portal-user";
import { create } from "@nju/http-client";
import { IconProvider } from "@nju/icons";
import {
  fetchProductOffers,
  IFetchProductOffersResponse,
  OfferProvider,
} from "@nju/data-access-portal-offers";
import sprite from "@nju/icons/sprite.svg";
import { BasicError, isError } from "@nju/result";
import { OverlayProvider } from "@nju/ui";
import { SSRProvider } from "@react-aria/ssr";
import { AppProps } from "next/app";
import Router from "next/router";
import * as React from "react";
import "../styles/index.css";
import { Debug, DebugProvider } from "@nju/debug";
import { Toaster, toast } from "react-hot-toast";
import { ErrorToast } from "../components/ErrorToast";
import { logout, SellerProvider } from "@nju/data-access-sales-seller";
import { getFeatureFlagValue } from "@nju/feature-flags";
import {
  CURRENT_ACTIVE_CLIENT_EMAIL,
  fetchActionToken,
} from "@nju/data-access-sales-user";
import { salesRoutes } from "@nju/routes";
import { Maintenance } from "../components/Maintenance";
import { DidomiSDK } from "@didomi/react";

create({
  hooks: {
    refreshToken: (scope) => {
      if (scope === "sales") {
        return maybeRefreshTokens({ scope });
      } else {
        const email = window.localStorage.getItem(CURRENT_ACTIVE_CLIENT_EMAIL);
        if (email) {
          return fetchActionToken({ email });
        }
      }
    },
    onUnauthorized: () => {
      logout().finally(() => {
        Router.push(salesRoutes.index.get());
      });
    },
  },
  options: {
    timeout: getFeatureFlagValue("longTimeouts") === "1" ? 60_000 : 20_000,
  },
});

let ErrorBoundary: BugsnagErrorBoundary | undefined;
let isBugsnagEnabled = false;

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  const isDev = process.env.NODE_ENV === "development";

  if (!isBugsnagEnabled) {
    isBugsnagEnabled = true;
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: isDev ? "local" : process.env.NEXT_PUBLIC_APP_ENV,
      appVersion: "portal-" + process.env.NEXT_PUBLIC_APP_VERSION,
      enabledReleaseStages: ["dev", "int", "prd"],
      logger: isDev
        ? undefined
        : // eslint-disable-next-line unicorn/no-null
          null,
      onError: (event) => {
        if (event.originalError instanceof BasicError) {
          if (getFeatureFlagValue("errorToasts") === "1") {
            toast.error(
              (t) => {
                return <ErrorToast error={event.originalError} toast={t} />;
              },
              {
                position: "bottom-center",
                duration: 1000 * 20,
              }
            );
          }
          event.addMetadata("info", { ...event.originalError });
        }
      },
    });
  }
  ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

export default function App(
  props: AppProps & { offers: IFetchProductOffersResponse; error?: boolean }
) {
  const didomiConfig = {
    app: {
      user: {
        externalConsent: {
          enabled: true,
        },
      },
    },
  };

  return (
    <SSRProvider>
      <DidomiSDK
        apiKey={process.env.NEXT_PUBLIC_DIDOMI_API_KEY}
        config={didomiConfig}
        embedTCFStub={true}
        gdprAppliesGlobally={true}
        iabVersion={2}
        noticeId={process.env.NEXT_PUBLIC_DIDOMI_NOTICE_ID}
        sdkPath="https://sdk.privacy-center.org/"
      />
      <DebugProvider>
        <SellerProvider
          allowUnauthorized={[
            salesRoutes.index.get(),
            salesRoutes.zaloguj.get(),
            salesRoutes.flags.get(),
            salesRoutes.trwajaPrace.get(),
            salesRoutes.regulaminyCenniki.get(),
            salesRoutes.regulaminyCennikiArchiwalne.get(),
            salesRoutes.obsluga.cookies.get(),
          ]}
          onUnauthorized={() => {
            logout().finally(() => {
              if (Router.pathname !== salesRoutes.zaloguj.get()) {
                Router.push(salesRoutes.zaloguj.get());
              }
            });
          }}
        >
          <NextSeo
            description="strefa konsultanta | nju.pl"
            nofollow={true}
            noindex={true}
            title="konsultant | nju.pl"
          />
          {!props.error && (
            <OfferProvider offers={props.offers}>
              <OverlayProvider>
                <IconProvider spritePath={sprite}>
                  <Maintenance scope="sales">
                    {ErrorBoundary ? (
                      <ErrorBoundary>
                        <props.Component {...props.pageProps} />
                        <Toaster />
                      </ErrorBoundary>
                    ) : (
                      <props.Component {...props.pageProps} />
                    )}
                    <Debug />
                  </Maintenance>
                </IconProvider>
              </OverlayProvider>
            </OfferProvider>
          )}
        </SellerProvider>
      </DebugProvider>
    </SSRProvider>
  );
}

App.getInitialProps = async () => {
  const result = await fetchProductOffers();
  if (isError(result)) {
    if (typeof window === "undefined") {
      throw result.err;
    } else {
      window.location.href = salesRoutes.ups.get();
    }
    return {
      error: true,
      offers: {},
    };
  }
  return {
    offers: result.value,
  };
};
