import {
  ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME,
  ACCESS_TOKEN_SALES_KEY_NAME,
  REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME,
  REFRESH_TOKEN_SALES_KEY_NAME,
} from "@nju/scope";
import { client } from "@nju/http-client";
import {
  ContractError,
  err,
  HttpError,
  isError,
  ok,
  Result,
  UnknownError,
} from "@nju/result";

export async function logout(): Promise<
  Result<ContractError | HttpError | UnknownError, void>
> {
  const body = new URLSearchParams();
  body.append("grant_type", "authorization_code");
  body.append(
    "client_id",
    process.env.NEXT_PUBLIC_CLIENT_ID_SALES || "unknown"
  );
  body.append(
    "refresh_token",
    localStorage.getItem(REFRESH_TOKEN_SALES_KEY_NAME) || "unknown"
  );
  const result = await client(
    `${process.env.NEXT_PUBLIC_IAM_HOST}/auth/realms/sales-agent/protocol/openid-connect/logout`,
    {
      method: "post",
      headers: {
        "Accept-Language": "pl",
        Cookie: "KEYCLOAK_LOCALE=pl",
      },
      scope: "sales",
      body,
    }
  );

  if (isError(result)) {
    return err(result.err);
  }

  window.localStorage.removeItem(ACCESS_TOKEN_SALES_KEY_NAME);
  window.localStorage.removeItem(REFRESH_TOKEN_SALES_KEY_NAME);
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME);
  window.localStorage.removeItem(REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME);

  return ok(undefined);
}
