import Bugsnag from "@bugsnag/js";
import { BasicError, err, isError, ok } from "@nju/result";
import { client } from "@nju/http-client";
import { ACCESS_TOKEN_KEY_NAME } from "@nju/scope";
import { serverResponse, verificationErrorResponse } from "./validators";
import { IFetchActionTokenResult } from "./types";
import { CURRENT_ACTIVE_CLIENT_EMAIL } from "../consts";

interface IFetchActionTokenParams {
  email: string;
}

export async function fetchActionToken({
  email,
}: IFetchActionTokenParams): Promise<IFetchActionTokenResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_PUBLIC_GATEWAY_HOST}/users/login/token`,
    {
      method: "post",
      validator: serverResponse,
      scope: "sales",
      headers: {
        "Accept-Language": "pl",
        "Client-Id": process.env.NEXT_PUBLIC_CLIENT_ID_SALES || "unknown",
      },
      json: {
        email,
      },
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    const errorInfo = verificationErrorResponse.safeParse(
      result.err.extra?.json
    );

    if (errorInfo.success && errorInfo.data.code === "50194") {
      return err(
        new BasicError({
          message: "client not found in keycloak",
          code: "CLIENT_NOT_FOUND",
        })
      );
    }

    return result;
  }

  window.localStorage.setItem(ACCESS_TOKEN_KEY_NAME, result.value.access_token);
  window.localStorage.setItem(CURRENT_ACTIVE_CLIENT_EMAIL, email);

  return ok({
    actionToken: result.value.access_token,
  });
}
