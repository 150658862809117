import * as React from "react";
import { useRouter } from "next/router";
import { maybeRefreshTokens } from "@nju/data-access-portal-user";

const Context = React.createContext<{
  isLoggedIn: boolean | undefined;
} | null>(
  // eslint-disable-next-line unicorn/no-null
  null
);

export function SellerLoginStateProvider({
  children,
  allowUnauthorized,
  onUnauthorized,
}: React.PropsWithChildren<{
  allowUnauthorized: string[];
  onUnauthorized: () => void;
}>) {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | undefined>(true);
  const router = useRouter();
  const allowUnauthorizedMatch = allowUnauthorized.includes(router.pathname);

  React.useEffect(() => {
    async function recheck() {
      await maybeRefreshTokens({
        canFail: true,
        justCheck: allowUnauthorizedMatch,
        scope: "sales",
      }).then((result) => {
        const isAuthorized = result.isOk;

        setIsLoggedIn(isAuthorized);

        if (!isAuthorized && !allowUnauthorizedMatch) {
          onUnauthorized();
        }
      });
    }
    recheck();

    const id = setInterval(recheck, 5000);

    router.events.on("routeChangeComplete", recheck);

    return () => {
      router.events.off("routeChangeComplete", recheck);
      clearInterval(id);
    };
  }, [
    allowUnauthorizedMatch,
    allowUnauthorized,
    onUnauthorized,
    router,
    router.events,
    router.pathname,
  ]);

  const value = React.useMemo(() => {
    return { isLoggedIn };
  }, [isLoggedIn]);

  return (
    <Context.Provider value={value}>
      {isLoggedIn || (!isLoggedIn && allowUnauthorizedMatch)
        ? children
        : // eslint-disable-next-line unicorn/no-null
          null}
    </Context.Provider>
  );
}

export function useSellerLoginState() {
  const value = React.useContext(Context);

  if (value === null) {
    console.warn("Missing `SellerProvider` in tree");

    return {
      isLoggedIn: false,
    };
  }

  return value;
}
